import styled from 'styled-components/macro'

export const Container = styled.div`
  display: flex;
  align-items: center;
  height: 48px;
  width: fit-content;
  box-shadow: var(--box-shadow);
`

export const Button = styled.button`
  display: flex;
  align-items: center;
  transition: all 0.2s;
  border: none;
  height: 100%;
  padding: 0 20px;
  font-size: 15px;
  font-family: 'ArticulatCF-DemiBold';
  cursor: pointer;
  background: var(--lighter-neutral-92);
  color: var(--darker-neutral-45);

  :hover {
    background: var(--lighter-neutral-85);
  }

  :not(:last-child) {
    border-right: 1px solid var(--lighter-neutral-85);
  }

  ${(props) =>
    !props.active && props.notification && `color: var(--darker-blue-20);`}

  ${(props) =>
    props.active &&
    `
    font-family: 'ArticulatCF-Bold';
    cursor: default;
    background: var(--lighter-neutral-99);
    color: var(--base-blue);

    :hover {
      background: var(--lighter-neutral-99);
    }
  `}

  ${(props) =>
    props.locked &&
    `
    pointer-events: none;

    ${
      !props.active &&
      `
      opacity: .5;
    `
    }
  `}
`
export const Notification = styled.div`
  height: 6px;
  width: 6px;
  background: var(--base-red);
  margin-left: 10px;
  border-radius: 50%;
`
