import React from 'react'
import moment from 'moment-timezone'

import getMissingValues from './getMissingValues'

import * as S from '../styles'

function getTableColumns({ columns, rows, timezone }) {
  const parsedColumns = columns.map((column, index) => {
    const weekDate = moment(column).tz(timezone).startOf('day')

    const isFutureDate = moment
      .tz(weekDate, timezone)
      .isAfter(moment.tz(timezone), 'day')

    const isSecondBimonthlyPeriod = weekDate.date() > 15

    const missingValuesPayload = {
      rows,
      index,
    }

    const missingValues = getMissingValues(missingValuesPayload)

    return {
      key: index,
      isFutureDate,
      missingValues,
      isSecondBimonthlyPeriod,
      text: (
        <>
          {weekDate.format('dd')}
          <S.HeaderDay
            isValueMissing={missingValues}
            isFutureDate={isFutureDate}
          >
            {weekDate.format('MM/DD')}
          </S.HeaderDay>
        </>
      ),
    }
  })

  parsedColumns.push({ key: 'periodToDate', text: 'PTD' })
  parsedColumns.push({ key: 'monthToDate', text: 'MTD' })

  return parsedColumns
}

export default getTableColumns
