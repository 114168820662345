import styled, { css } from 'styled-components'
import Select from 'react-select'
import ReactDatePicker from 'react-datepicker'
import { isEmpty, isNil } from 'ramda'
import { animationShake } from '../../css/sharedStyles'
import { headerHeight } from '../../config'
import dragHandlerIcon from '../../images/icons/icon_drag_handler.svg'

const ellipsis = css`
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  word-break: break-word;
`

const sharedStyle = css`
  padding: 15px 5px;
  display: block;
  font-family: var(--font-regular);

  ${(props) => props.strong && `font-family: var(--font-bold);`}
`

const sharedAddRemoveStyle = css`
  position: absolute;
  height: 18px;
  width: 18px;
  z-index: 999;
  border-radius: 50%;
  color: #fff;
  display: grid;
  align-content: center;
  display: none;
  left: 0px;

  :hover {
    cursor: pointer;
  }

  div {
    place-self: center;
    border-radius: 50%;
    line-height: 0.8;
  }
`

export const Header = styled.div`
  background-color: var(--lighter-neutral-92);
  display: flex;
  justify-content: space-between;
`

export const Cell = styled.div`
  text-align: center;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  width: 100%;

  ${(props) =>
    props.$width &&
    `
    width: ${props.$width}px !important;
  `}

  span {
    ${ellipsis}

    > div {
      ${ellipsis}
    }
  }

  border-right: 2.5px solid var(--base-neutral);

  :nth-child(1) {
    border-left: 2.5px solid var(--base-neutral);
  }
`

export const AddFirstRow = styled.div`
  ${sharedAddRemoveStyle}
  background: var(--base-blue);
  top: -4px;
`

export const AddRow = styled.div`
  ${sharedAddRemoveStyle}

  background: var(--base-blue);
  bottom: -4px;
`

export const RemoveRow = styled.div`
  ${sharedAddRemoveStyle}

  background: var(--base-red);
  top: 15px;
`

export const Row = styled.div`
  width: 100%;
  display: flex;
  position: relative;

  :first-child {
    ${RemoveRow} {
      top: 18px;
    }

    :hover {
      ${AddFirstRow} {
        top: 0;
      }
    }
  }

  :last-child {
    ${RemoveRow} {
      top: 12px;
    }

    ${AddRow} {
      bottom: -4px;
    }
  }

  :not(:last-of-type) {
    border-bottom: 1px solid;
  }

  :nth-child(even) {
    background: var(--lighter-neutral-96);
  }

  :nth-child(odd) {
    background: var(--lighter-neutral-99);
  }

  ${(props) =>
    props.$isDragEnabled &&
    `
    cursor: grab;

    :active {
      cursor: grabbing;
      background: var(--lighter-yellow-92) !important;
      z-index: 9999;
    }
  `}

  ${(props) =>
    props.$hasBeenMoved &&
    `
    background: var(--lighter-yellow-92) !important;
  `}

  ${(props) =>
    props.$isEditMode &&
    props.$showRowsControls &&
    `
    :hover {
      > ${AddRow}, ${RemoveRow} {
        display: grid;
      }

      :first-child {
        ${AddFirstRow} {
          display: grid;
        }
      }
  }
  `}
`

export const Body = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow: auto;
  height: calc(100% - 45px);

  ${(props) =>
    props.$isSelectable &&
    `
    height: 100%;
    max-height: 250px;

    ${Row} {
      ${AddRow} {
          bottom: 1px !important;
        }

      :first-child {
        ${RemoveRow} {
          top: 16px;
        }
      }
    }
  `}
`

export const Container = styled.div`
  width: 100%;
  padding: 10px;
  background: var(--lighter-neutral-99);
  max-height: 100%;
  position: relative;

  ${(props) =>
    props.$hasHorizontalScroll &&
    `
    overflow-y: hidden;
    overflow-x: scroll;

    ${Body}, ${Header} {
      display: inline-flex;
    }

    ${Cell} {
      width: 200px;
    }
  `}

  ${(props) =>
    props.$isCreatingNewTemplate &&
    `
      overflow: hidden;

      ${Body} {
        overflow: hidden;
      }
  `}

  ${(props) =>
    props.$prodSavings &&
    `
    max-height: fit-content;
  `}

  ${(props) =>
    props.$templateValues &&
    `
    max-height: calc(100% - 50px);
  `}

  ${(props) =>
    props.$isEditing &&
    `
    max-height: calc(100% - 100px);
    box-shadow: 0 0 0 5px var(--base-blue);
  `}

  ${(props) =>
    props.$isAffectedValues &&
    `
    ${Body} {
      max-height: 80px;
    }

    ${Cell} {
      font-size: 14px;
      height: 30px;
      padding: 0;
      line-height: 1;
      display: flex;

      > span {
        text-align: center;
        padding: 0 5px;
        align-self: center;
        justify-content: center;
      }
    }
  `}

  ${(props) =>
    props.$hasInnerTabs &&
    `
    max-height: calc(100% - 48px);
  `}
`

export const Text = styled.span`
  ${sharedStyle}

  ${(props) =>
    props.$isRequired &&
    `
    color: var(--base-red);
  `}
`

export const DragHandler = styled.div`
  position: absolute;
  height: 100%;
  width: 30px;
  background-image: url(${dragHandlerIcon});
  background-repeat: no-repeat;
  background-position: 1px center;
  background-size: 70%;
  cursor: grab;
  border-left: 2.5px solid var(--base-neutral);

  :active {
    cursor: grabbing;
  }
`

export const Wrapper = styled.div`
  height: 100%;
  background: rgba(0, 0, 0, 0.1);
`

export const LoadingContainer = styled.div`
  width: 100%;
  text-align: center;
  height: 200px;
  display: grid;
  background: var(--lighter-neutral-99);
  z-index: 9;

  ${(props) =>
    props.$fullSize &&
    `
    opacity: .9;
    position: absolute;
    top: 0;
    height: 100%;
    display: grid;
    place-items: center;
    align-content: center;
  `}
`

export const Button = styled.button`
  border: none;
  background-color: var(--base-blue);
  color: var(--lighter-neutral-99);
  padding: 0px 20px;
  margin-bottom: -10px;
  border-radius: 6px;

  :hover {
    background-color: var(--darker-blue-43);
  }
`

export const SaveButton = styled.button`
  width: 140px;
  color: var(--lighter-neutral-99);
  height: 45px;
  border-radius: var(--border-radius);
  border: none;
  text-align: center;
  transition: 0.2s all;
  margin: 0 15px 0 auto;
  margin-bottom: 15px;
  background: var(--base-blue);
  animation: ${(props) => !props.disabled && animationShake} 20s ease infinite;

  &:hover {
    ${(props) =>
      !props.unavailable &&
      !props.locked &&
      !props.editState &&
      'background: var(--base-blue)'};
  }

  ${(props) =>
    props.disabled &&
    `
    opacity: .5;
  `}

  ${(props) =>
    props.editState &&
    `
    background: var(${props.editColor});
  `}

  ${(props) =>
    (props.locked || props.unavailable) &&
    `
    background: var(--base-neutral);
  `}

  ${(props) =>
    props.locked &&
    `
    pointer-events: none;
  `}
`

export const Input = styled.input`
  ${sharedStyle}
  width: 100%;
  height: 100%;
  border: none;
  outline-color: var(--base-blue);
  border-radius: 0;
  text-align: center;

  background: ${(props) =>
    isEmpty(props.value)
      ? `var(--lighter-red-83-transparent);`
      : `var(--base-blue-transparent);`}

  ::placeholder {
    text-align: center;
    color: var(--lighter-red-83);
  }
`

export const CustomSelectContainer = styled(Select)`
  height: 100%;

  .react-select__control {
    border: none !important;
    font-family: 'ArticulatCF-Medium';
    color: var(--base-neutral);
    box-shadow: none;
    border-radius: 0;
    height: 100%;
    transform: 0.2s all ease;

    background: ${(props) =>
      props.defaultValue
        ? `var(--base-blue-transparent);`
        : `var(--lighter-red-83-transparent);`};
  }

  .react-select__input-container input {
    margin-left: 20px !important;
  }

  .react-select__placeholder {
    cursor: text;
    color: var(--lighter-red-83);
  }

  .react-select__indicator {
    width: 30px;
    color: var(--base-neutral);
  }

  .react-select__indicator-separator {
    display: none;
  }

  ${(props) =>
    props.isDisabled &&
    `

    .react-select__control{
      background: transparent;
    }

    .react-select__single-value--is-disabled {
      color: var(--darker-neutral-25);
    }

    .react-select__indicators {
      background: transparent;
      opacity: 0;
      margin-left: -30px;
    }
  `}
`

export const ScrollArea = styled.div`
  max-height: 200px;
  overflow-y: scroll;
`

export const Table = styled.table`
  width: 100%;
`

export const Small = styled.div`
  font-size: 14px;
  color: var(--base-red);
  display: flex;
  gap: 10px;
  justify-content: center;
`

export const Datepicker = styled(ReactDatePicker)`
  text-align: center;
  background: ${(props) =>
    isNil(props.selected)
      ? `var(--lighter-red-83-transparent);`
      : `var(--base-blue-transparent);`};

  width: 100% !important;
  height: 50px !important;
`

export const DoubleTableContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  height: calc(100% - ${headerHeight}px - 10px);
`

export const Flex = styled.div`
  display: flex;
  gap: 10px;

  div {
    min-width: 40%;
    margin: 0;
  }

  .react-select__control {
    flex-wrap: nowrap;
  }

  .react-select__indicators {
    min-width: 0;
  }
`
