import styled, { css } from 'styled-components/macro'
import { components } from 'react-select'
import { defaultCard } from '../../css/sharedStyles'

const grid = css`
  display: grid;
  grid-template-columns: 170px repeat(${(props) => props.length - 3}, 1fr) 130px 130px;
`

const cellBorder = css`
  border-right: 1px solid var(--lighter-neutral-85);
`

const sharedContainer = css`
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`

export const Container = styled.div`
  ${defaultCard}
  overflow-y: auto;
  margin: 0 auto;
  height: fit-content;
  border: 1px solid var(--base-neutral);
  width: calc(100% - 40px);

  ${(props) =>
    props.editMode !== 'view' &&
    (props.status !== 'locked' || props.editMode === 'comment') &&
    `border: 5px solid var(${props.editModeColor});`}
`

export const Body = styled.div`
  display: grid;
  grid-template-rows: repeat(${(props) => props.length}, 1fr);
`

export const Row = styled.div`
  ${grid}

  &:nth-child(even) {
    background-color: var(--lighter-neutral-96);
  }
`

export const CellWrapper = styled.div`
  ${cellBorder}
  opacity: .5;
`

export const Cell = styled.span`
  ${cellBorder}
  font-size: 0.9rem;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  height: 40px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;

  &:first-of-type {
    color: var(--darker-neutral-45);
    text-transform: uppercase;
    white-space: normal;

    ${(props) =>
      props.isIntercompanyServiceDescription &&
      `
      border-left: 5px solid ${
        props.isParentYard ? 'var(--base-blue)' : 'var(--base-purple)'
      };
    `}
  }

  &:last-of-type {
    border-right: none;
  }

  &:nth-last-child(2) {
    border-left: 3.5px solid var(--base-neutral);
  }

  ${(props) =>
    props.isClickEnabled &&
    `
    &:hover {
      cursor: pointer;
      border: 2px solid var(${props.editModeColor}) !important;
      opacity: 1;
    }
  `}

  ${(props) =>
    props.isDisabled &&
    `
    background-color: var(--lighter-neutral-96);
    opacity: .7;
    pointer-events: none;
  `}

  ${(props) =>
    props.isValueMissing && `background: var(--lighter-red-83-transparent);`}

  ${(props) => props.isMomService && `background: var(--lighter-neutral-92);`}
`

export const Header = styled.div`
  ${grid}
  background: var(--lighter-neutral-92);

  ${Cell} {
    color: var(--darker-neutral-45);
    font-size: 0.85rem;
    height: 60px;
  }
`

export const Input = styled.input`
  width: 100%;
  height: 100%;
  background: transparent;
  border: none;
  text-align: center;
  outline-color: var(--base-blue);

  ${(props) =>
    props.isValueMissing &&
    `
    outline-color: var(--base-red);
  `}

  ${(props) =>
    props.currentEditing &&
    `
    background-color: var(${props.editModeColor}-transparent);
  `}

  ${(p) => p.disabled && `opacity: .4`}
`

export const ClickableContainer = styled.div`
  ${sharedContainer}

  ${(props) =>
    props.currentEditing &&
    `
    background-color: var(${props.editModeColor}-transparent);
  `}
`

export const HiddenBlock = styled.div`
  display: none;

  ${(props) => props.isHidden && `display: none !important;`}
`

export const ObjectContainer = styled.div`
  ${sharedContainer};

  :hover {
    ${HiddenBlock} {
      display: block;
    }
  }
`

export const Icon = styled.div`
  position: absolute;
  top: 3px;
  opacity: 0.7;
  transition: 0.2s all ease;
  object-fit: cover;
  width: 15px;

  :hover {
    cursor: pointer;
    opacity: 1;
  }

  ${(props) => props.horizontalAlignment}: 3px;

  ${(props) =>
    props.isEmpty &&
    `
    opacity: .3;
  `}
`

export const Text = styled.span`
  ${(props) => props.isCommentRequired && `color: var(--base-red)`};
`

export const IconDelete = styled.img`
  width: 8px;
`

export const StyledControl = styled(components.Control)`
  padding: 2px 0;
  width: calc(100% + 36px);

  border: none !important;
  border-radius: 0 !important;
  cursor: pointer !important;

  background-color: ${(props) =>
    props.currentEditing
      ? `var(${props.editModeColor}-transparent)`
      : 'transparent'} !important;

  .react-select__indicators {
    transform: translate(-32px, 8px);
  }
`
