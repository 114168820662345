import styled from 'styled-components/macro'
import { Form } from 'formik'

import warningIcon from '../../images/icons/icon_warning.svg'

const borderColor = `var(--lighter-neutral-85)`
const defaultGap = `gap: 15px`
const defaultBorder = `1px solid ${borderColor}`

const defaultStyleForAccrual = `
  background: var(--lighter-yellow-94);
  color: var(--darker-yellow-27);
`

const defaultStyleForFinal = `
  background: var(--lighter-green-82);
  color: var(--darker-green-21);
`

const activeStyleForAccrual = `
  background: var(--base-yellow);
  color: var(--lighter-neutral-99);
  border-color: var(--base-yellow);
`

const activeStyleForFinal = `
  background: var(--darker-green-33);
  color: var(--lighter-neutral-99);
  border-color: var(--darker-green-33);
`

function getButtonStyleByModel({ model, isActive }) {
  return {
    accrual: isActive ? activeStyleForAccrual : defaultStyleForAccrual,
    final: isActive ? activeStyleForFinal : defaultStyleForFinal,
    back: `
      background: var(--lighter-neutral-99);
      color: var(--base-neutral);

      :hover {
        color: var(--darker-neutral-45);
      }
    `,
    submit: `
      background: var(--base-neutral);
      color: var(--lighter-neutral-92);
    `,
    submitted: `
      background: var(--base-blue);
      color: var(--lighter-neutral-99);
      border: var(--base-blue);
    `,
  }[model]
}

function getSubmitButtoActiveStyle({ submitType }) {
  return {
    accrual: activeStyleForAccrual,
    final: activeStyleForFinal,
  }[submitType]
}

export const ButtonWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;

  ${(props) =>
    props.submit &&
    `
    display: grid;
    grid-template-columns: .4fr 1fr;
    ${defaultGap};
  `}
`

export const Button = styled.button`
  width: 100%;
  height: 100%;
  border: 1px solid;
  border-color: inherit;
  transition: 0.2s all ease;
  text-transform: uppercase;

  ${(props) =>
    props.model &&
    getButtonStyleByModel({ model: props.model, isActive: props.active })}

  ${(props) =>
    props.value && getSubmitButtoActiveStyle({ submitType: props.value })}

  ${(props) =>
    props.disabled &&
    `
    opacity: .5;
  `}
`

export const Toggle = styled.button.attrs({ type: 'button' })`
  border: none;
  background: transparent;
  transition: 0.2s all ease;
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: flex-end;
  padding: 10px 0;
  margin-top: -20px;
  cursor: pointer;
  ${defaultGap};

  + .formItem {
    textarea {
      margin-top: 0;
    }
  }
`

export const IconArrow = styled.img`
  object-fit: contain;
  transition: 0.2s all ease;
  transform: rotate(180deg);
  width: 11px;

  ${(props) => props.isOpen && `transform: rotate(0deg);`}
`

export const Text = styled.span`
  transition: 0.2s all ease;

  ${(props) => props.status && `color: var(--base-blue);`}

  ${(props) =>
    props.width &&
    `
    width: ${props.width}px;
    overflow: hidden;
    text-overflow: ellipsis;
  `}

  ${(props) =>
    props.large &&
    `
    flex: 1;
    text-overflow: ellipsis;
    overflow: hidden;
    max-width: 150px;
  `}

  ${(props) =>
    props.gridEffect &&
    `
    padding: 10px 0 10px 10px;
    border-left: 2.5px solid ${borderColor};
  `}
`

export const Grid = styled.div`
  ${defaultGap};
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  height: 100%;
`

export const LeftGrid = styled.div`
  ${defaultGap};
  display: grid;
  grid-template-rows: repeat(2, 1fr);
  overflow: hidden;
`

export const RightGrid = styled.div`
  ${defaultGap};
  display: grid;
  grid-template-rows: 30px 1fr 140px 180px;
  overflow: hidden;
`

export const Box = styled.div`
  background: var(--lighter-neutral-99);
  box-shadow: var(--box-shadow);
  overflow: auto;
  width: 100%;
  height: 100%;

  ${(props) =>
    props.highlight &&
    `
    border: 5px solid var(--base-blue);
  `}

  ${(props) =>
    props.noShadow &&
    `
    box-shadow: none;
  `}

  ${(props) =>
    props.$noScroll &&
    `
    overflow: hidden;
  `}
`

export const Header = styled.div`
  background: var(--lighter-neutral-92);
  color: var(--darker-neutral-25);
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px 20px;
  font-size: 14px;
  font-family: 'ArticulatCF-Bold';
  text-transform: uppercase;
`

export const Body = styled.div`
  padding: 10px;
  display: grid;
  overflow: auto;
  height: calc(100% - 58px);
  ${defaultGap};

  ${(props) =>
    props.flexStart &&
    `
    align-content: flex-start;
  `}
`

export const Date = styled.span`
  display: inline-block;
  font-family: 'ArticulatCF-Bold';
`

export const Period = styled.span`
  display: inline-block;
  margin-left: 5px;
  padding-left: 5px;
  border-left: ${defaultBorder};
  text-transform: capitalize;
`

export const GrayArea = styled.div`
  background: var(--lighter-neutral-96);
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 10px;
  height: fit-content;

  ${(props) =>
    props.verticalCenter &&
    `
    align-items: center;
    flex-direction: row;
  `}

  ${(props) =>
    props.fitContent &&
    `
    height: fit-content;
  `}
`

export const Row = styled.div`
  display: flex;
  align-items: center;
  padding: 10px;
  justify-content: space-between;
  font-family: 'ArticulatCF-Bold';
  ${defaultGap};

  ${(props) =>
    props.tableHeader &&
    `
    font-family: 'ArticulatCF-Medium';
    font-size: 14px;
    color: var(--darker-neutral-45);

    ${Text} {
      text-transform: uppercase;
    }
  `}

  ${(props) =>
    props.isCharge &&
    `
    color: var(--base-red);
  `}

  ${(props) =>
    props.nowrap &&
    `
    white-space: nowrap;

    :not(:last-of-type){
      border-bottom: ${defaultBorder};
    }
  `}

  ${(props) =>
    props.nopadding &&
    `
    padding: 0;
  `}
`

export const Tier = styled.div`
  background: var(--lighter-neutral-92);
  width: 95%;
  margin: 0 auto;

  :not(:last-of-type) {
    border-bottom: ${defaultBorder};
  }

  :last-of-type {
    margin-bottom: 15px;
  }

  ${Row} {
    font-family: 'ArticulatCF-Medium';
  }
`

export const Details = styled.details`
  ${(props) =>
    props.hasChildren &&
    `
    > ${Row} {
      cursor: pointer;
      position: relative;
      transition: .2s all ease;
      padding-left: 12px;

      ::before {
        content: '';
        transition: .2s all ease;
        position: absolute;
        left: -4px;
        top: 18px;

        border-left: 5px solid transparent;
        border-right: 5px solid transparent;
        border-bottom: 5px solid var(--base-neutral);
        transform: rotate(90deg);
      }
    }

    &[open] > ${Row} {
      ::before {
        transform: rotate(180deg);
      }
    }
  `}

  :not(:last-of-type) {
    border-bottom: ${defaultBorder};
  }
`

export const StyledForm = styled(Form)`
  display: grid;

  ${(props) =>
    !props.isEmpty &&
    `
    align-content: flex-start;
  `}

  .formItem-label {
    font-size: 16px;
    margin-bottom: 10px;

    :before {
      content: '*';
      color: var(--base-red);
      margin-right: 2.5px;
    }
  }

  .formItem {
    padding-top: 0;
  }

  .inputItem {
    margin: 0 0 10px 0;
  }

  .formItem:not(:first-of-type) {
    .formItem-label {
      padding-top: 10px;
      border-top: ${defaultBorder};
    }
  }

  .textInput,
  .textAreaInput {
    background: var(--lighter-neutral-92);
    border: none;
    resize: none;
  }
`

export const MissingFuelPrice = styled.div`
  text-align: left;
  height: 70px;
  background: var(--lighter-neutral-92);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 10px 0 60px;
  border-radius: var(--border-radius);
  margin: 20px auto 0 auto;
  width: calc(100% - 20px);
  background-image: url(${warningIcon});
  background-repeat: no-repeat;
  background-position: center left 20px;
`

export const FormItemWrapper = styled.div`
  opacity: 0.7;
`

export const Menu = styled.div`
  margin-top: -3px;
`

export const FuelPriceBody = styled.div`
  overflow: auto;
  padding: 0 20px;
  height: calc(100% - 45px);

  .formItem {
    .inputItem {
      margin: 0;
    }
  }
`
