import styled from 'styled-components/macro'

export const Container = styled.div`
  display: flex;
  align-items: center;
  height: 48px;
  width: fit-content;
  box-shadow: var(--box-shadow);
  min-width: 0 !important;
`

export const IconWrapper = styled.div`
  margin-right: 5px;
`

export const TabItem = styled.button`
  display: flex;
  align-items: center;
  transition: all 0.2s;
  border: none;
  height: 100%;
  padding: 0 20px;
  font-size: 15px;
  font-family: 'ArticulatCF-Medium';
  transition: 0.2 all ease;
  cursor: pointer;
  text-transform: capitalize;
  position: relative;
  background: ${(props) =>
    props.isDarkMode ? 'var(--darker-blue-20)' : 'var(--lighter-neutral-92)'};
  color: ${(props) =>
    props.isDarkMode ? 'var(--base-neutral)' : 'var(--darker-neutral-45)'};

  :hover {
    background: ${(props) =>
      props.isDarkMode
        ? 'var(--darker-neutral-25)'
        : 'var(--lighter-neutral-85)'};
  }

  :not(:last-child) {
    border-right: 1px solid
      ${(props) =>
        props.isDarkMode
          ? 'var(--darker-neutral-45)'
          : 'var(--lighter-neutral-85)'};
  }

  ${(props) =>
    props.disabled &&
    `
    color: var(--base-neutral);
    opacity: .5;
    pointer-events: none;
  `}

  ${(props) =>
    props.active &&
    `
    opacity: 1;
    pointer-events: none;
    font-family: 'ArticulatCF-Bold';
    cursor: default;

    background: ${
      props.isDarkMode ? 'var(--darker-blue-29)' : 'var(--lighter-neutral-99)'
    };
    color: ${
      props.isDarkMode ? 'var(--lighter-neutral-99)' : 'var(--base-blue)'
    };
  `}
`

export const Notification = styled.div`
  height: 6px;
  width: 6px;
  background: var(--base-red);
  border-radius: 50%;
  position: absolute;
  left: 7px;
`

export const RedDot = styled.div`
  height: 8px;
  width: 8px;
  border-radius: 50%;
  background: red;
  margin-left: 10px;
`
