import styled from 'styled-components/macro'
import { NewTable, Button } from '../../components'
import { animationShake } from '../../css/sharedStyles'

export const Gap = styled.div`
  height: 10px;
  width: 100%;
`

export const Name = styled.span`
  text-transform: capitalize;
`

export const StyledNewTable = styled(NewTable)`
  .table-body {
    padding-bottom: 130px;
  }
`
export const Row = styled.div`
  display: flex;
  align-items: center;
  gap: 15px;
`

export const ButtonWrapper = styled.div`
  display: flex;
  gap: 20px;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  margin-top: 45px;
  margin-bottom: 15px;
`

export const StyledButton = styled(Button)`
  display: block;
  border: none;
  background-color: var(--base-blue);
  padding: 12px 0;
  font-size: 16px;
  border-radius: var(--border-radius);
  color: var(--lighter-neutral-99);
  animation: ${animationShake} 25s ease infinite;
  animation-delay: 60s;
  transition: 0.1s all;
  font-family: 'ArticulatCF-DemiBold';
  margin: 0;

  :hover {
    background-color: var(--darker-blue-43);
  }

  &.cancelBtn {
    background-color: var(--lighter-neutral-99);
    color: var(--base-blue);
    border: 1px solid var(--base-blue);
    animation: none;

    :hover {
      ${(props) =>
        props.warning
          ? `
            background-color: var(--base-red);
            color: var(--lighter-neutral-99);
            border: 1px solid var(--base-red);
            `
          : `
            background-color: var(--lighter-neutral-85);
        `}
    }
  }
`

export const ActionContainer = styled.div`
  display: flex;
  gap: 10px;
`

export const Action = styled.button`
  border: none;
  height: 30px;
  width: 30px;
  background: var(--base-blue);
  border-radius: 50%;
  display: grid;
  place-items: center;
  transition: 0.2s all ease;

  :hover {
    background: var(--darker-blue-29);
  }

  ${(props) =>
    props.disabled &&
    `
    opacity: .1;
    pointer-events: none;
  `}
`

export const Image = styled.img`
  object-fit: contain;
  height: 100%;
  width: 100%;
`
