import styled from 'styled-components'
import { animationShake } from '../../css/sharedStyles'

const syntaxBuilder = (backgroundColor, color) => `
  background-color: ${backgroundColor};
  color: ${color};
`

const getBadgeColorByStatus = ({ status }) =>
  ({
    tbd: syntaxBuilder('var(--lighter-neutral-92)', 'var(--darker-neutral-45)'),
    done: syntaxBuilder('var(--lighter-green-82)', 'var(--darker-green-21)'),
    empty: syntaxBuilder('var(--lighter-red-87)', 'var(--darker-red-35)'),
    partial: syntaxBuilder(
      'var(--lighter-yellow-87)',
      'var(--darker-yellow-27)',
    ),
  }[status])

export const Battery = styled.img`
  width: 54px;
  position: absolute;
  left: -6px;
`

export const SubMenu = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 10px;
`

export const EngineHoursContainer = styled.div`
  background: var(--lighter-neutral-99);
  padding: 20px 0;
  margin-top: 20px;
  text-align: center;
  overflow: auto;
  box-shadow: var(--box-shadow);
`

export const HeaderDay = styled.span`
  display: block;
  width: 100%;
  height: 100%;
  color: var(--darker-neutral-25) !important;
`

export const Button = styled.button`
  width: 150px;
  color: var(--lighter-neutral-99);
  height: 45px;
  border-radius: var(--border-radius);
  border: none;
  text-align: center;
  transition: 0.2s all;
  margin-left: 20px;
  background: var(--base-blue);

  animation: ${(p) => p.editState && animationShake} 20s ease infinite;
  ${(p) =>
    p.editState &&
    `
    background: var(${p.editColor});
  `}

  ${(p) =>
    p.secondary &&
    `
    background: var(--lighter-neutral-99);
    color: var(--base-blue);
    box-shadow: var(--box-shadow);
  `}

  ${(p) => p.disabled && `opacity: .5;`}
`

export const FlexContainer = styled.div`
  display: flex;
  align-items: center;
`
export const Badge = styled.div`
  ${(props) => getBadgeColorByStatus({ status: props.status })};
  font-size: 10px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  margin-bottom: 8px;
`
export const Icon = styled.img`
  object-fit: contain;
  height: 10px;
`
