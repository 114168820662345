import styled, { css } from 'styled-components/macro'
import { defaultCard } from '../../css/sharedStyles'

const grid = css`
  display: grid;
  grid-template-columns: 170px repeat(${(props) => props.length - 1}, 1fr);
`

export const Container = styled.div`
  ${defaultCard}
  text-align: center;
  padding: 10px;
  overflow-y: auto;
  width: 100%;
  margin: 0 auto;
  max-height: fit-content;
  min-height: 300px;
  cursor: default;
  display: grid;
`

export const Header = styled.div`
  ${grid}

  & span {
    color: var(--base-neutral);
    font-size: 0.85rem;
  }
`

export const Body = styled.div`
  display: grid;
  grid-template-rows: repeat(${(props) => props.length}, 1fr);
`

export const Row = styled.div`
  ${grid}

  &:nth-child(even) {
    background-color: var(--lighter-neutral-96);
  }
`

export const Cell = styled.span`
  text-align: center;
  font-size: 0.9rem;
  border-right: 1px solid var(--lighter-neutral-85);
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;

  &:first-of-type {
    color: var(--darker-neutral-45);
    text-transform: uppercase;
    white-space: normal;
    justify-content: flex-start;
  }

  &:last-of-type {
    border-right: none;
  }
`
