import styled from 'styled-components/macro'
import { animationShake } from '../../css/sharedStyles'

export const Footer = styled.div`
  height: 80px;
  padding: 0 10px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
`

export const Button = styled.button`
  width: 200px;
  color: var(--lighter-neutral-99);
  height: 45px;
  border-radius: var(--border-radius);
  border: none;
  text-align: center;
  transition: 0.2s all;
  margin-right: 10px;
  background: var(--base-blue);

  &:hover {
    ${(props) =>
      !props.unavailable &&
      !props.locked &&
      !props.editState &&
      'background: var(--base-blue)'};
  }

  animation: ${(props) => props.editState && animationShake} 20s ease infinite;
  ${(props) =>
    props.editState &&
    `
    background: var(${props.editColor});
  `}

  ${(props) =>
    (props.locked || props.unavailable) &&
    `
    background: var(--base-neutral);
  `}

  ${(props) =>
    props.locked &&
    `
    pointer-events: none;
  `}
`

export const HeaderDay = styled.span`
  display: block;
  width: 100%;
  margin-top: -25px;

  color: var(--darker-green-33) !important;

  ${(props) =>
    props.isValueMissing &&
    `
    color: var(--base-red) !important;
  `}

  ${(props) =>
    props.isFutureDate &&
    `
    color: var(--darker-neutral-25) !important;
  `}
`

export const ServiceGroup = styled.div`
  background: var(--lighter-neutral-99);
  box-shadow: var(--box-shadow);
  padding: 20px 0;
  text-align: center;

  :not(:first-of-type) {
    margin-top: 40px;
  }
`

export const Content = styled.div`
  height: 100%;
  overflow-y: auto;
`

export const CommentBox = styled.div`
  display: ${(props) =>
    props.screenX > 0 && props.screenY > 0 ? 'block' : 'none'};
  position: absolute;
  width: 200px;
  height: 200px;
  background: white;
  z-index: 999;
  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.2);
  left: ${(props) => `calc(${props.screenX}px + 100px)`};
  top: ${(props) => `calc(${props.screenY}px / 1.5)`};
  transition: 0.2s all ease;
`

export const Wrapper = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  gap: 20px;
`

export const TooltipText = styled.div`
  :not(:last-of-type) {
    margin-bottom: 8px;
  }
`

export const EmptyContainer = styled.div`
  height: 200px;
`
